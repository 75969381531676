@media only screen and (max-width: 1024px) {
  .topbar:not(.topbar-small):not(.topbar-fixed) {
    display: none;
  }
}
.topbar {
  background: #003f8f;
  color: #fff;
}
.topbar .topbar-i {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
}
.topbar .topbar-i .links-w .links {
  margin: 0;
  padding: 10px;
}
.topbar .topbar-i .links-w .links .link {
  display: inline-block;
  border-left: 2px solid rgba(0,0,0,0.15);
  white-space: nowrap;
  padding: 0 6px 0 8px;
}
.topbar .topbar-i .links-w .links .link a {
  font-size: 12px;
  color: #fff;
  text-decoration: none;
}
.topbar .topbar-i .links-w .links .link a:hover {
  opacity: 0.75;
}
.topbar .topbar-i .links-w .links .link:first-child {
  border: none;
}
.topbar .topbar-i .links-w .links .link:last-child:before {
  content: '';
  width: 11px;
  height: 11px;
  border-radius: 1rem;
  display: inline-block;
  background: #8ba3cf;
  margin-bottom: -0.1rem;
  margin-right: 0.35rem;
  animation: 2s linear infinite pulse;
}
.topbar .topbar-i .student-area-w a,
.topbar .topbar-i .books-w a {
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.topbar .topbar-i .student-area-w a span,
.topbar .topbar-i .books-w a span {
  margin-left: 3px;
}
