@import "../global/___variables"

.topbar:not(.topbar-small):not(.topbar-fixed)
    +below(1024px)
        display none
.topbar
    background colorp
    color white
    .topbar-i
        display flex
        align-items: center
        justify-content space-between
        height 44px
        .links-w
            .links
                margin 0
                padding 10px
                .link
                    display inline-block
                    border-left: 2px solid rgba(0,0,0,.15)
                    white-space: nowrap
                    padding: 0 6px 0 8px
                    a
                        font-size 12px
                        color white
                        text-decoration none
                        &:hover
                            opacity .75
                    &:first-child
                        border none
                    &:last-child:before
                        content: ''
                        width: 11px
                        height: 11px
                        border-radius: 1rem
                        display: inline-block
                        background: #8BA3CF
                        margin-bottom: -.1rem
                        margin-right: .35rem
                        animation: 2s linear infinite pulse
        .student-area-w
        .books-w
            a
                display flex
                align-items: center
                background transparent
                border 1px solid white
                color white
                span
                    margin-left 3px